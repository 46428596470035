/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "influxapi",
            "endpoint": "https://pjphfymyy3.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "integration",
            "endpoint": "https://qsogxt5mh8.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "webhook",
            "endpoint": "https://03beqabuv7.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:a12a42a0-d10e-4da0-a984-2381db2b86cd",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_QRBCSPW7t",
    "aws_user_pools_web_client_id": "7niq0160vhjlnkbnpsarmh9g4n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "commissioningCompletedMessage-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "controllableLoadConsumption-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discoreco-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discorecoTasks-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "gmexport-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "group-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "groupusers-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrol-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrolhistory-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterDataExportHistory-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterEvents-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterStatusRefreshHistory-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterstatus-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "onsitemetercomm-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "org-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "policy-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "provisioningCompletedMessage-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "service-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tasks-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "touLatestReportMessage-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "user-production",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
