import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';

import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

import { AuthorizationState } from './store/states/authorization.state'
import { ProgressState } from './store/states/progress.state'
import { ServiceState } from './store/states/service.state'
import { UserState } from './store/states/user.state'
import { UserService } from './store/services/user/user.service';
import { OrganizationState } from './store/states/organization.state'
import { DiscorecoState } from './store/states/discoreco.state'
import { OrganizationService } from './store/services/organization/organization.service';
import { IxError } from './utils/ix-error';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md'
    }),
    AppRoutingModule,
    MaterialModule,
    AmplifyAuthenticatorModule,
    // NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
    NgxsModule.forRoot(
      [
        AuthorizationState, ProgressState, ServiceState, UserState, OrganizationState, DiscorecoState
      ],
      { developmentMode: !environment.production }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        // UserState,
      ],
      storage: StorageOption.SessionStorage
    })
  ],
  providers: [
    UserService,
    OrganizationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: ErrorHandler, useClass: IxError },
  ],
  bootstrap: [AppComponent],
  exports: [],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
